<template>
    <div class="ProductLibrary f-cl">
        <el-page-header @back="backPrivious" content="产品库"></el-page-header>
        <div class="f-w-b">
            <NavigationBar></NavigationBar>
            <div class="main">
                <!-- 标题 -->
                <div class="banner">
                    {{ enterpriseStateInfo.enterpriseName }}
                </div>
                <div class="featureBox f-c-b">
                    <div class="flex">
                        <div class="pb-20 mr-50 paneText" v-for="(item, index) of paneList" :key="index"
                            @click="changeTab(item.name)"
                            :class="openSearchFlag == item.name ? 'pitchOn' : 'NopitchOn'">
                            {{ item.label }}</div>
                    </div>
                    <router-link class="add-product" to="/ProductLibraryAdd" v-if="p_l == 'W'">
                        <el-button type="primary"><i class="el-icon-circle-plus mr-10"></i>新增产品</el-button>
                    </router-link>
                </div>
                <ul class="f-c-b mb-60 ulStyle">
                    <li class="ta-center paddingTB-10 liStyle-none f1 hover-pointer"
                        v-for="(item, index) of productLibraryListScan" :key="index"
                        :class="activeScan == index ? 'liText' : ''" @click="HandleScan(index)">
                        {{ item.title }}
                        <i v-if="activeScan == index && item.sort != ''"
                            :class="item.sort == 1 ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"></i>
                    </li>
                </ul>
                <div class="w--100 f-c-c">
                    <ProductLibrarySearchBox ref="ProductLibrarySearchBox" @changeProductType="changeProductType"
                        @handleSearchBox="handleSearchBox" :enterpriseId="enterpriseId + ''"></ProductLibrarySearchBox>
                </div>
                <span class="resultTips" v-if="searchProductValue">搜索到 {{ total }} 条与 “{{ openSearchFlag == 'Y' ?
            paneList[0].inputText : paneList[1].inputText }}” 相关产品</span>
                <span class="resultTips" v-else>产品总数：{{ total }} 条</span>
                <div class="productlibrary_List boxShadow" v-if="productList && productList.length">
                    <router-link :to="{
            name: 'ProductLibraryDetail', query: {
                key: $UrlEncode.encode(JSON.stringify({
                    id: item.productId
                }))
            }
        }" class="productlibrary_Item f-c" v-for="(item, index) of productList" :key="index">
                        <el-image class="item-image" :src="item.img1Url" fit="contain"></el-image>
                        <div class="f-c-b f1 pl-40">
                            <div class="item_left f-cl-fs">
                                <span class="productName">{{ item.productName }}</span>
                                <span class="productNumber">{{ item.productNumber ? item.productNumber : ' ' }}</span>
                                <div class="price" v-if="item.unitPrice">
                                    <span class="valuationCurrency">{{ item.valuationCurrency }}</span>
                                    <span class="unitPrice">{{ item.unitPrice ?? item.unitPrice.toFixed(2) }}</span>
                                </div>
                                <div class="price" v-else>
                                    <span class="unitPrice">暂无报价</span>
                                </div>
                            </div>
                            <div class="item_right f-cl">
                                <span
                                    :style="{ color: item.inventoryQuantity != 0 && item.shortageFlag == 'N' ? '#999' : '#FF4E36', fontSize: '16px' }">{{
            item.shortageFlag == 'Y' ? '对外显示缺货' : (item.inventoryQuantity == 0 ? '缺货' : '在售')
        }}</span>
                                <span class="mt-10" style="color: #999;">库存数量：{{ item.inventoryQuantity.toFixed(2)
                                    }}</span>
                            </div>
                            <div class="item_border"></div>
                            <div class="cornermark">{{ item.productTypeStr }}</div>
                        </div>
                    </router-link>
                </div>
                <el-empty v-else description="暂无相关产品！"></el-empty>
                <Pagination v-if="productList && productList.length" :total="total" :pageNum.sync="currentPage"
                    :limit.sync="size" :layout="layout" @pagination="fetchData" />
            </div>
        </div>
    </div>
</template>

<script>
import { PaginationRetention } from '@/mixins/PaginationRetentionMixin';
import { VerificationFormatMethods } from '@/mixins/VerificationFormat';
import { UnitAccount } from "@/utils/attributeValue1Options";
export default {
    name: 'ProductLibrary',
    mixins: [PaginationRetention, VerificationFormatMethods],
    components: {
        NavigationBar: () => import(/* webpackChunkName: "UploadVideo" */ '../components/NavigationBar.vue'),
        ProductLibrarySearchBox: () => import(/* webpackChunkName: "UploadVideo" */ '../components/ProductLibrarySearchBox.vue')
    },
    data() {
        return {
            openSearchFlag: "Y",//开放搜索标记(Y:开放,N:关闭)
            productList: [],
            paneList: [{
                label: '对外开放',
                name: 'Y',
                inputText: ''
            }, {
                label: '不对外开放',
                name: 'N',
                inputText: ''
            }],
            // --------------
            options1: [{
                value: '0',
                label: '名称'
            }, {
                value: '1',
                label: '编号'
            }],
            options2: [],
            productLibraryListScan: [{
                title: '默认',
                sort: ''
            }, {
                title: '名称',
                sort: 1,//1-升序 2-降序
            }, {
                title: '编号',
                sort: 1,//1-升序 2-降序
            }, {
                title: '价格',
                sort: 1
            }, {
                title: '库存',
                sort: 1
            }],
            select1: '名称',
            searchProductFlag: 0,//(0:根据产品名称查找,1:根据产品编号进行查找)
            select2: 0,
            productType: 0, // 产品类型
            inputText: '',
            enterpriseStateInfo: {},
            p_l: '',
            enterpriseId: '',
            // searchType: 'name',
            searchProductValue: '',//搜索产品值
            activeScan: 0,//默认选中名称列表
            activeName: 0,//点击名称的次数
            activeNumber: 0,//点击编号的次数
            activePrice: 0,//点击价格的次数
            activeStock: 0,//点击库存的次数
            sortField: '',//排序字段( 产品名称排序: productName, 库存数量排序: inventoryQuantity, 产品单价排序: unitPrice, 产品编号排序: productNumber)
            sortOrder: '',//排序规则，默认降序 (升序: ascend, 降序: descend)
        }
    },
    created() {
        this.enterpriseStateInfo = this.$getStorage('enterpriseStateInfo');
        this.enterpriseId = this.enterpriseStateInfo.enterpriseId;
        this.p_l = this.enterpriseStateInfo.userPermission.p_l;
        this.findSearchProductTypeList();
        this.$removeStorage('ProductLibrary_params');
        this.$removeStorage('picker_color');
        this.$removeStorage('picker_colorName');
        this.$removeStorage('csState');
    },
    methods: {
        HandleScan(value) {
            this.activeScan = value
            switch (value) {
                // 默认
                case 0:
                    this.sortField = '';
                    this.sortOrder = '';
                    this.$setStorage('handleScanValue', { activeScan: value, sortField: '' })
                    break;
                //名称
                case 1:
                    this.activeName++;
                    this.sortField = 'productName';
                    if (this.activeName % 2 == 1) {
                        this.productLibraryListScan[value].sort = 1
                        this.sortOrder = 'ascend'
                    } else {
                        this.productLibraryListScan[value].sort = 2
                        this.sortOrder = 'descend'
                    }
                    this.$setStorage('handleScanValue', { activeScan: value, handleActive: this.activeName, value, sortOrder: this.sortOrder, sortField: this.sortField })
                    break;
                //产品编号排序
                case 2:
                    this.activeNumber++;
                    this.sortField = 'productNumber';
                    if (this.activeNumber % 2 == 1) {
                        this.productLibraryListScan[value].sort = 1
                        this.sortOrder = 'ascend'
                    } else {
                        this.productLibraryListScan[value].sort = 2
                        this.sortOrder = 'descend'
                    }
                    this.$setStorage('handleScanValue', { activeScan: value, handleActive: this.activeNumber, value, sortOrder: this.sortOrder, sortField: this.sortField })
                    break;
                //产品单价排序
                case 3:
                    this.activePrice++;
                    this.sortField = 'unitPrice';
                    if (this.activePrice % 2 == 1) {
                        this.productLibraryListScan[value].sort = 1
                        this.sortOrder = 'ascend'
                    } else {
                        this.productLibraryListScan[value].sort = 2
                        this.sortOrder = 'descend'
                    }
                    this.$setStorage('handleScanValue', { activeScan: value, handleActive: this.activePrice, value, sortOrder: this.sortOrder, sortField: this.sortField })
                    break;
                //库存数量排序
                case 4:
                    this.activeStock++;
                    this.sortField = 'inventoryQuantity';
                    if (this.activeStock % 2 == 1) {
                        this.productLibraryListScan[value].sort = 1
                        this.sortOrder = 'ascend'
                    } else {
                        this.productLibraryListScan[value].sort = 2
                        this.sortOrder = 'descend'
                    }
                    this.$setStorage('handleScanValue', { activeScan: value, handleActive: this.activeStock, value, sortOrder: this.sortOrder, sortField: this.sortField })
                    break;
            }
            this.paging = 1
            this.findSearchEnterpriseProductList(this.paging, this.pagingSize, this.searchProductValue)
        },
        // 用户查询企业状态以及信息接口
        findEnterpriseAuditStateAndInfo() {
            let that = this;
            that.$http.findEnterpriseAuditStateAndInfo({
                applicantId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token')
            }).then(res => {
                that.enterpriseStateInfo = res.data;
                that.p_l = res.data.userPermission.p_l
                that.$setStorage('enterpriseStateInfo', res.data)
            })
        },
        async findSearchProductTypeList() {
            let data = {
                page: 1,
                size: 10
            }
            await this.$http.findSearchProductTypeList(data).then(res => {
                if (res.code == 200) {
                    let records = res.data.records,
                        odj = {};
                    records.forEach(item => {
                        odj = {
                            value: item.productTypeId,
                            label: item.productTypeName
                        }
                        this.options2.push(odj)
                    });
                }
            })
        },
        getDate() {
            this.$getStorage('ProductLibrarySearchValue') ? this.searchProductValue = this.$getStorage('ProductLibrarySearchValue') : '';
            if (this.$getStorage('ProductLibraryOpenSearchFlag') && this.$getStorage('ProductLibraryOpenSearchFlag') == "Y") {
                this.paneList[0].inputText = this.searchProductValue
            }
            if (this.$getStorage('ProductLibraryOpenSearchFlag') && this.$getStorage('ProductLibraryOpenSearchFlag') == "N") {
                this.paneList[1].inputText = this.searchProductValue
            }
            let handleScanValue = this.$getStorage('handleScanValue') ? this.$getStorage('handleScanValue') : ''
            if (handleScanValue) {
                let { handleActive, value, sortOrder, activeScan, sortField } = handleScanValue
                this.activeScan = activeScan;
                handleActive ? (this.productLibraryListScan[value].sort = handleActive % 2 == 1 ? 1 : 2) : ''
                this.sortOrder = sortOrder;
                this.sortField = sortField;
            }
            this.findSearchEnterpriseProductList(this.paging, this.pagingSize, this.searchProductValue);
        },
        // 切换tabs
        changeTab(value) {
            this.$refs.ProductLibrarySearchBox.cleanInputText();
            this.openSearchFlag = value;
            this.productList = [];
            let InputValue = '';
            this.searchProductValue = '';
            this.paneList[0].inputText = '';
            this.paneList[1].inputText = '';
            this.findSearchEnterpriseProductList(1, this.size, InputValue);
        },
        // 用户搜索查看企业产品列表接口
        findSearchEnterpriseProductList(page = this.page, size = this.size, searchProduct) {
            let that = this;
            that.$http.findSearchEnterpriseProductList({
                lastModifyUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                page,
                size,
                sortField: that.sortField,//排序字段(产品名称排序: productName,库存数量排序: inventoryQuantity,产品单价排序: unitPrice,产品编号排序: productNumber)
                sortOrder: that.sortOrder,//排序规则，默认降序(升序: ascend,降序: descend)
                openSearchFlag: that.openSearchFlag,
                searchProductFlag: that.searchProductFlag,
                productType: that.$getStorage('ProductLibrary_productType') ?? 0,
                searchProduct
            }).then(res => {
                if (res.code == 200) {
                    let records = res.data.records;
                    records.forEach(item => {
                        item.productTypeStr = UnitAccount.getproductType(item.productType)
                    })
                    that.productList = records;
                    that.currentPage = res.data.current;//当前页数
                    that.total = res.data.total
                }
            })
        },
        // -------------------------------------------------------------
        // 切换搜索框搜索条件
        changeSearchSelect(value) {
            this.searchProductFlag = value;
        },
        changeProductType(value) {
            this.productType = value;
            this.$setStorage('ProductLibrary_productType', value);
            this.search();
        },
        // 文本搜索
        search() {
            let InputValue = '';
            this.openSearchFlag == 'Y' ? InputValue = this.paneList[0].inputText : InputValue = this.paneList[1].inputText;
            this.searchProductValue = InputValue
            this.$setStorage('ProductLibrarySearchValue', InputValue)
            this.$setStorage('ProductLibraryOpenSearchFlag', this.openSearchFlag)
            this.findSearchEnterpriseProductList(1, this.size, InputValue)
        },
        handleSearchBox(inputText, select, searchProductFlag) {
            if (this.openSearchFlag == 'Y') {
                this.paneList[0].inputText = inputText;
            } else {
                this.paneList[1].inputText = inputText;
            }
            this.$setStorage('ProductLibrary_productType', select);
            this.searchProductFlag = searchProductFlag;
            this.search();
        }

    },
}

</script>
<style>
.ProductLibrary {
    .main {
        width: 980px;

        .banner {
            background: url('../../../assets/supply/supplyBanner.png');
            height: 100px;
            color: #FFFFFF;
            font-size: 30px;
            line-height: 100px;
            text-align: center;
            margin-bottom: 40px;
        }

        .featureBox {
            height: 50px;

            .paneText {
                font-size: 24px;
                font-weight: 500;
                color: #333333;
                cursor: pointer;
            }

            .pitchOn {
                border-bottom: 3px solid #0270F2;
                color: #0363FA;
                cursor: pointer;
            }

            .NopitchOn {
                color: #333333;
                cursor: pointer;
            }

            .add-product {
                height: 50px;
                width: 120px;
                /* border: 1px solid red; */
                border-radius: 10px;

                /* align-self: flex-end; */
                /deep/.el-button--primary {
                    background: linear-gradient(101deg, #006EF2 0%, #33A6F9 100%) !important;
                }

                /deep/.el-button {
                    background: linear-gradient(101deg, #006EF2 0%, #33A6F9 100%) !important;
                }
            }
        }

        .ulStyle {
            border: none !important;
            height: 60px;
            background: #F3F8FE;

            .liText {
                color: #0363FA;
            }

        }

        .productlibrary_List {
            width: 975px;
            border-radius: 10px;

            .productlibrary_Item {
                position: relative;
                height: 140px;
                padding: 30px 50px;
                cursor: pointer;

                .item-image {
                    width: 140px;
                    height: 140px;
                    border-radius: 20px;
                }

                .item_left {
                    .productName {
                        font-size: 20px;
                        color: #000000;
                    }

                    .productNumber {
                        font-size: 16px;
                        margin: 15px 0 30px 0;
                        color: #999999;
                    }

                    .price {
                        color: #FF4E36;

                        .valuationCurrency {
                            font-size: 19px;
                        }

                        .unitPrice {
                            font-size: 24px;
                        }
                    }
                }

                .item_border {
                    position: absolute;
                    bottom: 0;
                    right: 30px;
                    height: 1px;
                    width: 915px;
                    border-bottom: 1px solid #F3F3F3;
                }

                .cornermark {
                    position: absolute;
                    right: 50px;
                    top: 20px;
                    border-radius: 5px;
                    background: #E6F1FE;
                    text-align: center;
                    /* height: 30px; */
                    line-height: 16px;
                    font-size: 16px;
                    color: #0573F2;
                    padding: 5px;
                }
            }

            .productlibrary_Item:hover {
                background-color: rgb(233, 250, 255);
                cursor: pointer;
            }
        }
    }
}
</style>
